import * as React from "react"
import LiftTable from "./LiftTable"

const Percentages = ({ toggleSettings, lifts }) => {
  return (
    <>
      {lifts.length ? (
        <LiftTable lifts={lifts} />
      ) : (
        <div className="flex flex-col items-center py-8">
          <h1 className="self-center text-black font-bold text-lg">
            Set your 1RMs to get started.
          </h1>
          <button
            type="button"
            className="inline-flex items-center px-3 py-3 border border-transparent text-lg leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 uppercase mt-4"
            onClick={toggleSettings}
          >
            Set Lifts
          </button>
        </div>
      )}
    </>
  )
}
export default Percentages
