import * as React from "react"
import Input from "./Input"

const Settings = () => {
  return (
    <div className="py-4 px-4 sm:max-w-3xl mb-[30vh]">
      <h1 className="text-2xl font-extrabold tracking-tight text-black mb-4">
        1 Rep Max
      </h1>

      <div className="grid gap-4 grid-cols-2">
        <div>
          <h1 className="text-xl font-bold tracking-tight text-black mb-2 underline uppercase">
            Squat
          </h1>
          <Input name="Back Squat" />
          <Input name="Front Squat" />
          <Input name="Overhead Squat" />
          <h1 className="text-xl font-bold tracking-tight text-black mb-2 underline uppercase">
            Oly
          </h1>
          <Input name="Snatch" />
          <Input name="Clean & Jerk" />
          <Input name="Jerk" />
          <Input name="Power Clean" />
          <Input name="Power Snatch" />
        </div>
        <div>
          <h1 className="text-xl font-bold tracking-tight text-black mb-2 underline uppercase">
            Press
          </h1>
          <Input name="Bench Press" />
          <Input name="Strict Press" />
          <Input name="Push Press" />
          <h1 className="text-xl font-bold tracking-tight text-black mb-2 underline uppercase">
            Deadlift
          </h1>
          <Input name="Deadlift" />
        </div>
      </div>
    </div>
  )
}

export default Settings
