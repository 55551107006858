import * as React from "react"
import "./App.css"
import Nav from "./components/Nav"
import Settings from "./components/Settings"
import Percentages from "./components/Percentages"
import { getLifts } from "./helpers"

function App() {
  const { useState } = React
  const [showSettings, setShowSettings] = useState(false)
  const toggleSettings = () => {
    window.scrollTo(0, 0)
    setShowSettings(!showSettings)
  }
  const lifts = getLifts()
  return (
    <div>
      <Nav toggleSettings={toggleSettings} showSettings={showSettings} />
      <div>
        {showSettings ? (
          <Settings />
        ) : (
          <Percentages toggleSettings={toggleSettings} lifts={lifts} />
        )}
      </div>
    </div>
  )
}

export default App
