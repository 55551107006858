import * as React from "react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const LiftTable = ({ lifts }) => {
  return (
    <div className="flex flex-wrap mb-[30vh]">
      {lifts.map((lift, index) => (
        <div key={index} className="sm:w-1/3 md:w-1/4 w-1/2">
          <div
            key={index}
            className="px-4 py-3 text-left text-lg font-extrabold text-white uppercase tracking-wide bg-red-500 sticky top-16 z-10"
          >
            {lift.name}
          </div>
          <div className="border-r-2 border-red-500">
            <table className="whitespace-nowrap w-full bg-white border-b-2 border-red-500">
              <tbody>
                <tr className="border-y border-gray-400">
                  <td className="px-4 py-3 text-left text-lg font-extrabold text-black uppercase  w-12 bg-white border-r border-gray-400">
                    {`1RM`}
                  </td>
                  <td
                    key={index}
                    className="px-4 py-3 whitespace-nowrap text-lg font-extrabold text-black border-gray-400 text-left bg-white"
                  >
                    {`${lift.oneRepMax} lbs`}
                  </td>
                </tr>
                {Object.keys(lift.percentages)
                  .reverse()
                  .map((percentage, percentageIndex) => (
                    <tr
                      key={percentageIndex}
                      className="border-t border-gray-400"
                    >
                      <td
                        className={classNames(
                          "px-4 py-3 text-left text-lg font-extrabold text-black border-r border-gray-400",
                          percentageIndex % 2 === 1
                            ? "bg-gray-100"
                            : "bg-gray-300"
                        )}
                      >
                        {`${percentage}%`}
                      </td>
                      <td
                        key={index}
                        className={classNames(
                          "px-4 py-3 whitespace-nowrap text-lg font-normal text-black text-left border-gray-400 ",
                          percentageIndex % 2 === 1
                            ? "bg-gray-100"
                            : "bg-gray-300"
                        )}
                      >
                        {`${lift.percentages[percentage]} lbs`}
                      </td>
                    </tr>
                  ))}
                {/* {Object.keys(lifts[0].percentages)
                    .reverse()
                    .map((percentage) => (
                      <tr>
                        <td className="px-4 py-3 text-left text-sm font-extrabold text-black uppercase tracking-wider bg-white sticky left-0">
                          {`${percentage}%`}
                        </td>
                      </tr>
                    ))} */}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
    // <div className="overflow-auto max-h-[calc(100vh_-_4rem)]">
    //   <div className="mb-[25rem]">
    //     <table className="whitespace-nowrap table-fixed">
    //       <thead>
    //         <tr>
    //           <th className="sticky top-0 bg-white z-10"></th>
    //           {lifts.map((lift, index) => (
    //             <th
    //               key={index}
    //               scope="col"
    //               className="px-4 py-3 text-left text-sm font-extrabold text-white uppercase tracking-wider bg-red-500 sticky top-0 z-10"
    //             >
    //               {lift.name}
    //             </th>
    //           ))}
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td className="px-4 py-3 text-left text-sm font-extrabold text-black uppercase tracking-wider bg-white sticky left-0">
    //             {`1RM`}
    //           </td>
    //           {lifts.map((lift, index) => (
    //             <td
    //               key={index}
    //               className="px-4 py-3 whitespace-nowrap text-sm font-bold text-black bg-white"
    //             >
    //               {`${lift.oneRepMax} lbs`}
    //             </td>
    //           ))}
    //         </tr>
    //         {Object.keys(lifts[0].percentages)
    //           .reverse()
    //           .map((percentage) => (
    //             <tr key={percentage}>
    //               <td className="px-4 py-3 text-left text-sm font-extrabold text-black uppercase tracking-wider bg-white sticky left-0">
    //                 {`${percentage}%`}
    //               </td>
    //               {lifts.map((lift, index) => (
    //                 <td
    //                   key={index}
    //                   className="px-4 py-3 whitespace-nowrap text-sm font-normal text-black bg-slate-100 w-52"
    //                 >
    //                   {`${lift.percentages[percentage]} lbs`}
    //                 </td>
    //               ))}
    //             </tr>
    //           ))}
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  )
}
export default LiftTable
