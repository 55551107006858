import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear, faTimes } from "@fortawesome/free-solid-svg-icons"

const Nav = ({ toggleSettings, showSettings }) => {
  return (
    <div className="h-16 bg-black shadow-md p-4 flex justify-between items-center sticky top-0 z-20">
      <h1 className="text-3xl font-extrabold font-bungee uppercase tracking-tight text-white sm:text-3xl ">
        Scrapyard
      </h1>
      <FontAwesomeIcon
        className="text-white h-6"
        icon={showSettings ? faTimes : faGear}
        onClick={toggleSettings}
      />
    </div>
  )
}

export default Nav
