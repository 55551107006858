import * as React from "react"

const Input = ({ name }) => {
  const { useState, useEffect } = React
  const [value, setValue] = useState(localStorage.getItem(name))

  useEffect(() => {
    if (value) localStorage.setItem(name, value)
    else localStorage.removeItem(name)
  }, [name, value])

  return (
    <div className="mb-2">
      <label className="block text-lg font-medium text-black">{name}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="number"
          pattern="[0-9]*"
          className="focus:ring-black focus:border-black block w-full pr-12 sm:text-sm border-gray-500 border rounded-md text-black placeholder:text-black"
          onChange={(event) => setValue(event.currentTarget.value)}
          value={value}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-black text-md">lbs</span>
        </div>
      </div>
    </div>
  )
}

export default Input
