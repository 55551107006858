export const getLifts = () => {
  const lifts = [
    "Back Squat",
    "Front Squat",
    "Overhead Squat",
    "Bench Press",
    "Strict Press",
    "Push Press",
    "Snatch",
    "Clean & Jerk",
    "Jerk",
    "Power Clean",
    "Power Snatch",
    "Deadlift",
  ]
  const oneRepMaxes = []
  lifts.forEach((lift) => {
    const value = localStorage.getItem(lift)
    const obj = { name: lift, oneRepMax: value, percentages: {} }
    for (let i = 0.1; i < 1; i += 0.05) {
      obj.percentages[Math.round(i * 100)] = Math.round(value * i)
    }
    if (value) oneRepMaxes.push(obj)
  })
  return oneRepMaxes
}
